/**
 * Use to consume constants stored on the window object.
 *
 * This approach allows arbitrary constants but forces them to be
 * explicitly declared.
 *
 * If there are globals present across our environment, they can be added
 * as defaults here.
 */
export default function <Globals_ = {}>(): Window & Globals_ {
  return window as unknown as Window & Globals_;
}
