import React from 'react';
import ReactDOM from 'react-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import _ from 'lodash';
import Window from '../lib/Window';

interface AlertMessage {
  msgType: string;
  message: string;
  autoClose?: boolean;
  uniqid: number;
  displayTime?: number;
}

interface AlertProps {
  message: AlertMessage;
  displayTime?: number;
  onClose: (msg: AlertMessage) => void;
}

export class FlashAlert extends React.Component<AlertProps> {
  componentDidMount(): void {
    if (this.props.message.autoClose) {
      setTimeout(this.handleClose, this.props.displayTime || 5000);
    }
  }

  handleClose = (): void => {
    this.props.onClose(this.props.message);
  };

  render(): JSX.Element {
    const { msgType, message } = this.props.message;
    return (
      <div className={`alert alert-${msgType}`} role="alert">
        <button
          type="button"
          className="btn btn-close me-4"
          onClick={this.handleClose}
          aria-label="Close"
        />
        {message}
      </div>
    );
  }
}

type IncomingMessage = [string, string];

interface ContainerProps {
  messages: IncomingMessage[];
}

interface ContainerState {
  messages: AlertMessage[];
}

const PIN_TO_TOP: React.CSSProperties = {
  position: 'sticky',
};
export default class FlashMessages extends React.Component<
  ContainerProps,
  ContainerState
> {
  private messageId: number;

  constructor(props) {
    super(props);
    // Absolute counter for message id
    this.messageId = 0;

    if (!this?.props?.messages) {
      console.warn({
        msg: 'no messages passed to flash messenger',
        props: this.props,
      });
    } else {
      console.log(this, this.props);
    }
    this.state = {
      messages: (this?.props?.messages || []).map(msg =>
        this._createMessage(msg[0], msg[1]),
      ),
    };
  }

  addSuccess = (
    message: string,
    autoClose = true,
    displayTime = 5000,
  ): boolean => this.handleAdd('success', message, autoClose, displayTime);

  addDanger = (
    message: string,
    autoClose = true,
    displayTime = 5000,
  ): boolean => this.handleAdd('danger', message, autoClose, displayTime);

  addWarning = (
    message: string,
    autoClose = true,
    displayTime = 5000,
  ): boolean => this.handleAdd('warning', message, autoClose, displayTime);

  handleAdd = (
    msgType: string,
    message: string,
    autoClose = true,
    displayTime = 5000,
  ): boolean => {
    const msg = this._createMessage(msgType, message, autoClose, displayTime);
    this.setState({ messages: this.state.messages.concat([msg]) });
    return true;
  };

  handleAddOnce = (
    msgType: string,
    message: string,
    autoClose = true,
    displayTime = 5000,
  ): boolean => {
    if (_.find(this.state.messages, { message })) {
      return false;
    }
    return this.handleAdd(msgType, message, autoClose, displayTime);
  };

  handleAddReplace = (
    previousMessage: string,
    msgType: string,
    message: string,
    autoClose = true,
    displayTime = 5000,
  ): void => {
    const messages = _.filter(
      this.state.messages,
      msg => msg.message !== previousMessage,
    );
    this.setState({ messages }, () =>
      this.handleAdd(msgType, message, autoClose, displayTime),
    );
  };

  handleRemove = (msg: AlertMessage): void => {
    this.setState({ messages: _.without(this.state.messages, msg) });
  };

  _createMessage(
    msgType: string,
    message: string,
    autoClose = true,
    displayTime = 5000,
  ): AlertMessage {
    const typeMap = {
      message: 'info',
      error: 'danger',
    };
    this.messageId += 1;
    return {
      msgType: msgType in typeMap ? typeMap[msgType] : msgType,
      message,
      autoClose,
      displayTime,
      uniqid: this.messageId,
    };
  }

  render(): JSX.Element {
    const messageList = (this.state.messages || []).map(message => (
      <CSSTransition
        classNames="alert"
        key={message.uniqid}
        timeout={{ exit: 400, enter: 500 }}>
        <FlashAlert
          message={message}
          displayTime={message.displayTime}
          onClose={this.handleRemove}
        />
      </CSSTransition>
    ));
    return (
      <div style={PIN_TO_TOP}>
        <TransitionGroup>{messageList}</TransitionGroup>
      </div>
    );
  }
}

interface FlashGlobals {
  flashMessenger: void;
  flashMessages?: IncomingMessage[];
}

// Hack: we need to move to a real compilation system
const flashElem = document.getElementById('flashMessages');
if (flashElem && ReactDOM) {
  Window<FlashGlobals>().flashMessenger = ReactDOM.render(
    <FlashMessages messages={Window<FlashGlobals>().flashMessages || []} />,
    flashElem,
  );
} else {
  console.warn(`Could not mount flash messaging, ${flashElem}`);
}
